<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        id="modal-signature"
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel1">
              Masukkan passphrase untuk tanda tangan
            </h4>
            <button
              type="button"
              @click="returnValue(false)"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <CustomInput
              :required="true"
              :label="'Passphrase'"
              size="12"
              type="password"
              :model="form.password"
              @data="setValue('password', $event)"
            />
            <!-- <div class="row">
							<div class="col-md-12">
								<div class="row">
									<label for="error" class="control-label col-md-6">
										Tanda Tangan
										<small style="color: red;">*</small>
									</label>
								</div>
							</div>
						</div> -->
            <!-- <div class="row">
							<div class="col-md-12">
								<div class="row">
									<div class="col-md-12 col-xs-12 row">
										<div class="col-md-6">
											<input
												type="radio"
												checked="checked"
												id="coretan"
												name="coretan"
												value="1"
												v-model="form.choice"
												class="m-r-10"
											/>
											<label for="coretan">Coretan Tangan</label>&nbsp;
										</div>
										<div class="col-md-6">
											<input
												type="radio"
												:checked="false"
												id="Gambar"
												name="Gambar"
												value="0"
												class="m-r-10"
												v-model="form.choice"
											/>
											<label for="Gambar">Gambar</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="row">
									<label for="error" class="control-label col-md-6">
										Metode
										<small style="color: red;">*</small>
									</label>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-12">
								<div class="row">
									<div class="col-md-12 col-xs-12 row">
										<div class="col-md-6">
											<input
												type="radio"
												checked="checked"
												id="bsre"
												name="bsre"
												value="1"
												v-model="form.choice2"
												class="m-r-10"
											/>
											<label for="bsre">BSRE</label>&nbsp;
										</div>
										<div class="col-md-6">
											<input
												type="radio"
												:checked="false"
												id="p12"
												name="p12"
												value="0"
												class="m-r-10"
												v-model="form.choice2"
											/>
											<label for="p12">P12</label>
										</div>
									</div>
								</div>
							</div>
						</div> -->
            <!-- <div class="row"> -->
            <!-- <div class="col-md-12">
								<div class="row">
									<label for="error" class="control-label col-md-6">
										Ukuran Kertas
										<small style="color: red;">*</small>
									</label>
								</div>
							</div> -->
            <!-- </div> -->
            <!-- <div class="row"> -->
            <!-- <div class="col-md-12">
								<div class="row">
									<div class="col-md-12 col-xs-12 row">
										<div class="col-md-6">
											<input
												type="radio"
												checked="checked"
												id="a4"
												name="a4"
												value="1"
												v-model="form.choice3"
												class="m-r-10"
											/>
											<label for="a4">A4</label>&nbsp;
										</div>
										<div class="col-md-6">
											<input
												type="radio"
												:checked="false"
												id="f4"
												name="f4"
												value="0"
												class="m-r-10"
												v-model="form.choice3"
											/>
											<label for="f4">F4</label>
										</div>
									</div>
								</div>
							</div> -->
            <!-- </div> -->
            <VueSignaturePad
              id="signature"
              v-show="form.coretan"
              :options="{
                onBegin: () => {
                  $refs.signaturePad.resizeCanvas();
                },
              }"
              width="100%"
              height="200px"
              ref="signaturePad"
            />
            <button
              v-if="form.coretan"
              type="button"
              class="btn btn-warning"
              @click.prevent="undo"
            >
              Undo
            </button>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-info"
              @click="returnValue(true)"
            >
              Oke
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-dismiss="modal"
              @click="returnValue(false)"
            >
              Batal
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "default",
  data() {
    let user = JSON.parse(localStorage.getItem("user"));
    return {
      form: {
        gambar: true,
        coretan: false,
        choice: 0,
        choice2: 1,
        choice3: 1,
        bsre: true,
        p12: false,
        f4: false,
        a4: true,
        password: user.passphrase,
      },
    };
  },
  watch: {
    "form.choice"(newValue) {
      if (newValue == 1) {
        this.form.coretan = true;
        this.form.gambar = false;
      } else {
        this.form.coretan = false;
        this.form.gambar = true;
      }
    },
    "form.choice2"(newValue) {
      if (newValue == 1) {
        this.form.bsre = true;
        this.form.p12 = false;
      } else {
        this.form.bsre = false;
        this.form.p12 = true;
      }
    },
    "form.choice3"(newValue) {
      if (newValue == 1) {
        this.form.a4 = true;
        this.form.f4 = false;
      } else {
        this.form.a4 = false;
        this.form.f4 = true;
      }
    },
  },
  methods: {
    setValue(model, data) {
      this.form[model] = data;
    },
    returnValue(pay) {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      let payload = Object.assign(this.form, {
        status: pay,
        signature: data,
        isEmpty: isEmpty,
      });
      if (pay) {
        if (
          this.form.choice !== null &&
          this.form.choice2 !== null &&
          this.form.choice3 !== null &&
          this.form.password !== ""
        ) {
          if (this.form.coretan == true && !isEmpty) {
            this.$emit("exit", payload);
          } else if (this.form.coretan == false) {
            this.$emit("exit", payload);
          }
        }
      } else {
        this.$emit("exit", payload);
      }
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
  },
};
</script>
<style>
.modal-backdrop {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #ffffff;
  position: relative !important;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid #eeeeee;
  color: #4aae9b;
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  border: none;
  font-size: 20px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  color: #4aae9b;
  background: transparent;
}

.btn-green {
  color: white;
  background: #4aae9b;
  border: 1px solid #4aae9b;
  border-radius: 2px;
}
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>
<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
