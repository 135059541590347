<template>
	<div>
		<div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
			<div class="col col-lg-2"></div>
			<div class="col-md-auto mt-20">
				<custom-loader></custom-loader>
			</div>
			<div class="col col-lg-2"></div>
		</div>
		<div class="card" v-else>
			<div class="card-body">
				<form action="#">
					<div class="form-body">
						<h3 class="card-title">
							{{ $t('drafting') }}
							<button
								class="btn btn-warning float-right"
								v-if="editMode && canEdit"
								@click.prevent="disableAll = !disableAll"
								:title="$t('edit')"
							>
								<i class="fa fa-pencil"></i> {{ $t('edit') }}
							</button>
							<small v-if="!canEdit && editMode" style="font-size:9px;" class="float-right">
								<b>
									<i>
										Anda saat ini bukan Unit Kerja/Staff yang sedang aktif untuk memberikan
										persetujuan/wewenang mengubah drafting ini.
									</i>
								</b>
							</small>
						</h3>
						<hr />
						<div class="row p-t-20" v-if="base64preview">
							<div class="col-md-12 m-t-10 m-b-10">
								<iframe
									:src="base64preview"
									:type="type"
									allowfullscreen
									style="width:100%;height:100vh;"
								></iframe>
							</div>
						</div>
						<div class="row p-t-20">
							<CustomInput
								:required="true"
								:disabled="true"
								:label="$t('agenda_number')"
								size="4"
								:model="form.agenda_number"
								@data="setValue('agenda_number', $event)"
							/>
							<CustomMulti
								:disabled="disableAll"
								:required="true"
								:label="$t('document_urgency')"
								size="4"
								:query="urgencies"
								:list="urgenciesList"
								:model="form.document_urgency"
								@data="setValue('document_urgency', $event)"
							/>
							<CustomInput
								:disabled="disableAll"
								:required="true"
								:label="$t('date')"
								size="4"
								:model="form.document_date"
								@data="setValue('document_date', $event)"
							/>
						</div>
						<div class="row p-t-20">
							<CustomInput
								:required="true"
								:disabled="disableAll"
								:label="$t('page')"
								size="4"
								type="number"
								:model="form.page"
								@data="setValue('page', $event)"
							/>
							<CustomInput
								:disabled="disableAll"
								:required="true"
								:label="$t('attachment')"
								size="2"
								type="number"
								:model="form.attachment"
								@data="setValue('attachment', $event)"
							/>
							<CustomMulti
								:label="''"
								:disabled="form.attachment == 0 || disableAll"
								size="2"
								:query="attachmentType"
								:list="attachmentTypeList"
								:model="form.attachment_type"
								@data="setValue('attachment_type', $event)"
							/>
							<CustomMulti
								:label="$t('document_speed')"
								size="4"
								:disabled="disableAll"
								:required="true"
								:query="documentSpeed"
								:list="documentSpeedList"
								:model="form.document_speed"
								@data="setValue('document_speed', $event)"
							/>
						</div>
						<div class="row p-t-20">
							<CustomMulti
								:label="$t('document_type')"
								size="4"
								:disabled="disableAll"
								:required="true"
								:query="documentType"
								:list="documentTypeList"
								:model="form.document_type"
								@data="setValue('document_type', $event)"
							/>
							<CustomMulti
								:label="$t('document_name')"
								size="4"
								:required="true"
								:disabled="!form.document_type || disableAll"
								:query="documentName"
								:list="filteredDocumentName"
								:model="form.document_name"
								@data="setValue('document_name', $event)"
							/>
							<CustomMulti
								:label="$t('classification')"
								size="4"
								:required="true"
								:disabled="!form.document_name || disableAll"
								:query="documentClasification"
								:list="documentClasificationList"
								:model="form.document_clasification"
								@data="setValue('document_clasification', $event)"
							/>
						</div>
						<div class="row p-t-20">
							<!-- <CustomCheck
								:label="'Dengan Kop Surat'"
								size="4"
								:disabled="disableAll"
								:model="form.with_header"
								@data="setValue('with_header', $event)"
							/>
							<CustomCheck
								:label="'Dengan Kop Surat Atasan'"
								size="4"
								:disabled="disableAll"
								v-if="form.document_type.value !== undefined && form.document_type.value == 48"
								:model="form.with_superior_header"
								@data="setValue('with_superior_header', $event)"
							/> -->
							<CustomCheck
								:label="'Kirim Sebagai Personal'"
								size="4"
								:disabled="disableAll"
								v-if="form.document_type.value !== undefined && form.document_type.value == 53"
								:model="form.as_personal"
								@data="setValue('as_personal', $event)"
							/>
						</div>
						<div class="row p-t-20">
							<CustomInput
								:required="true"
								:label="$t('subject')"
								size="12"
								:disabled="disableAll"
								:model="form.subject"
								@data="setValue('subject', $event)"
							/>
						</div>
						<div class="row p-t-20">
							<CustomTree
								:required="true"
								:disabled="disableAll"
								:label="$t('receivers')"
								size="12"
								:list="filteredReceiversList"
								:model="form.receivers"
								@action="getReceiverChildren"
								@data="setValue('receivers', $event)"
							/>
							<template v-for="(el, index) in receiversLabeling">
								<CustomCheck
									:key="index"
									:label="'Gunakan sebagai label untuk ' + el.label"
									size="4"
									:disabled="disableAll"
									:model="el.as_label"
									@data="setValueLabeling(el, 'sb', 'as_label', $event)"
								/>
							</template>
						</div>
						<div class="row p-t-20">
							<CustomTree
								:disabled="disableAll"
								:label="$t('carbons')"
								size="12"
								:list="filteredCarbonsList"
								:model="form.carbons"
								@action="getCarbonChildren"
								@data="setValue('carbons', $event)"
							/>
							<template v-for="(el, index) in carbonsLabeling">
								<CustomCheck
									:key="index"
									:label="'Gunakan sebagai label untuk ' + el.label"
									size="4"
									:disabled="disableAll"
									:model="el.as_label"
									@data="setValueLabeling(el, 'cc', 'as_label', $event)"
								/>
							</template>
						</div>
						<div class="row p-t-20">
							<CustomTree
								v-if="computedIsUnitPengolah"
								:required="true"
								:multiple="false"
								:label="computedLabelPengolah"
								size="6"
								:disableBranchNode="true"
								:disabled="disableAll"
								:list="processingsList"
								:model="form.processing"
								@action="getProcessingChildren"
								@data="setValue('processing', $event)"
							/>
							<CustomTree
								v-if="computedIsPenandatangan"
								:required="true"
								:multiple="false"
								:label="$t('signing')"
								size="6"
								:disableBranchNode="true"
								:disabled="disableAll"
								:list="signingsList"
								:model="form.signing"
								@action="getSigningChildren"
								@data="setValue('signing', $event)"
							/>
						</div>
						<div class="row p-t-20">
							<div class="col-md-12 m-t-10 m-b-10">
								<div class="form-group">
									<label>Isi Surat <span v-if="this.content">: isi surat sudah di draft sebelumnya, silahkan <a class="btn btn-success" :href="link_file" target="_blank"><i class="fa fa-download"></i> klik di sini</a> untuk mengunduh isi surat saat ini atau jika ingin membuat dari awal bisa menggunakan format <a class="btn btn-success" target="_blank" :href="form.document_name.link_file"><i class="fa fa-download"></i> berikut</a>.</span><span v-else>: <a class="btn btn-success" target="_blank" :href="form.document_name.link_file"><i class="fa fa-download"></i> klik di sini</a> untuk mengunduh template isi surat.</span></label>
									<drop-file-docx @data="assignFileDocx"></drop-file-docx>
								</div>
							</div>
						</div>
						<!-- <div class="row p-t-20">
							<div class="col-md-12 m-t-10 m-b-10">
								<label :class="['control-label']">Isi Surat</label>
								<summernote
									:disabled="disableAll"
									class="form-control"
									name="editor"
									:model="content"
									v-on:change="
										(value) => {
											content = value;
										}
									"
								></summernote>
							</div>
						</div> -->
						<div class="row">
							<div class="col-md-12">
								<div class="form-group">
									<label :class="['control-label']">{{ $t('file') }} {{ $t('attachment') }}</label>
									<h6 v-if="!files.length">{{ $t('no_file') }}</h6>
									<table v-else class="table table-stripped" id="sortable-list">
										<tr>
											<th>{{ $t('file') }}</th>
											<th>{{ $t('action') }}</th>
										</tr>
										<tr
											v-for="file in files"
											v-bind:key="file.filename"
											class="draggable"
											style="cursor:drag;cursor:move;"
										>
											<td>
												{{ file.name }}
											</td>
											<td>
												<span v-if="file.filepath !== undefined">
													<a
														class="btn btn-success text-white"
														@click="download(file.uuid, file.filename)"
														><i class="fa fa-download text-white"></i>
													</a>
													<a @click="prev(file.uuid)" class="btn btn-info text-white"
														><i class="fa fa-eye text-white"></i>
													</a>
												</span>
												<!-- <i v-else
                          ><b>{{ $t("not_saved") }} &nbsp;&nbsp;</b></i
                        > -->
												<a
													v-if="!disableAll"
													class="btn btn-danger text-white"
													@click="removeAttachment(file.uuid)"
												>
													<i class="fa fa-trash-o text-white"></i>
												</a>
											</td>
										</tr>
									</table>

									<Gallery v-show="!disableAll" :uploader="uploader" />
								</div>
							</div>
						</div>
						<div class="row p-t-20" v-if="editMode && canEdit">
							<div class="col-md-12 m-t-10 m-b-10">
								<label :class="['control-label']">Catatan Persetujuan/Penolakan/Revisi</label>
								<summernote
									class="form-control"
									name="editor2"
									:model="form.note"
									v-on:change="
										(value) => {
											form.note = value;
										}
									"
								></summernote>
							</div>
						</div>
						<div class="form-actions">
							<button
								type="submit"
								class="btn btn-info m-r-10"
								v-if="!editMode"
								@click.prevent="saveOnly"
								title="Simpan"
							>
								<i class="fa fa-check"></i> {{ $t('save') }}
							</button>
							<button
								type="submit"
								class="btn btn-success"
								v-if="!editMode"
								@click.prevent="submit"
								title="Simpan"
							>
								<i class="fa fa-send"></i> {{ $t('save_and_send') }}
							</button>
							<button
								type="submit"
								class="btn btn-warning m-r-10"
								v-if="editMode"
								@click.prevent="previewDraft"
								title="Preview"
							>
								<i class="fa fa-check"></i> {{ 'Preview' }}
							</button>
							<button
								type="submit"
								class="btn btn-success m-r-10"
								v-if="editMode && canEdit"
								@click.prevent="saveAndPreview"
								title="Simpan And Preview"
							>
								<i class="fa fa-save"></i> {{ 'Simpan And Preview' }}
							</button>
							<button
								type="submit"
								class="btn btn-info m-r-10"
								v-if="editMode && canEdit && finalApproval && finalSender"
								@click.prevent="showSign"
								title="Setujui dan Tanda Tangani"
							>
								<i class="fa fa-check"></i> {{ 'Setujui dan Tanda Tangani' }}
							</button>
							<button
								type="submit"
								class="btn btn-info"
								v-if="editMode && canEdit && !finalApproval"
								@click.prevent="submit(1)"
								title="Simpan"
							>
								<i class="fa fa-send"></i> {{ $t('save_and_send') }}
							</button>
							&nbsp;
							<!-- <button
                v-if="editMode"
                :disabled="isDisabledSubmit"
                type="submit"
                class="btn btn-success"
                @click="setApproval(1)"
                title="Kirim"
              >
                <i class="mdi mdi-send"></i> {{ $t("send") }}
              </button> -->
							<!-- &nbsp; -->
							<button type="button" @click="cancel" class="btn btn-inverse" title="Kembali">
								{{ $t('back') }}
							</button>
							<button
								type="button"
								@click="submit(3)"
								v-if="editMode && canEdit && !to_staff"
								class="btn btn-danger float-right"
								title="Tolak Draft Ini"
							>
								{{ 'Tolak Draft Ini' }}
							</button>
							<button
								type="button"
								@click="submit(2)"
								v-if="editMode && canEdit && !to_staff"
								class="btn btn-danger float-right m-r-10"
								title="Revisi Draft Ini"
							>
								{{ 'Revisi Draft Ini' }}
							</button>
						</div>
						<signatureFile @exit="signingProcess" v-show="sign" />
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import Sortable from 'sortablejs';
import axios from 'axios';
import signatureFile from '../../../components/modal/signatureFile.vue';

export default {
	components: { signatureFile },
	data() {
		const uploader = this.fineUploaderPdf(this, 'drafting');
		return {
			uploader,
			base64preview: '',
			type: '',
			files: [],
			sign: false,
			link_file:'',
			form: {
				agenda_number: '',
				// document_number: "",
				document_type: '',
				document_name: '',
				document_speed: '',
				subject: '',
				receivers: null,
				carbons: null,
				document_clasification: '',
				with_header: true,
				as_personal: false,
				with_superior_header: false,
				document_urgency: '',
				attachment_type: { value: 2, text: 'Lembar' },
				attachment: 0,
				page: 1,
				note: '-',
				status: 2,
				processing: null,
				signing: null
			},
			disableAll: false,
			content: '',
			canEdit: false,
			to_staff: false,
			finalApproval: false,
			finalSender: false,
			finalSender: false,
			urgenciesList: [],
			documentSpeedList: [],
			documentClasificationList: [],
			documentNameList: [],
			receiversList: [],
			carbonsList: [],
			signingsList: [],
			processingsList: [],
			filteredCarbonsList:[],
			filteredReceiversList:[],
			attachmentTypeList: [],
			documentTypeList: [],
			receiversLabeling: [],
			carbonsLabeling: []
		};
	},
	computed: {
		computedLabelPengolah(){
			if(this.isUnitPengolah && this.isPenandatangan){
				return this.$t('processing');
			}else{
				return this.$t('signing');
			}
		},
		isPenandatangan() {
			return this.form.document_name.code !== undefined
				? this.form.document_name.code.includes('Penandatangan')
				: false;
		},
		computedIsPenandatangan() {
			let isPenandatangan =  this.form.document_name.code !== undefined
				? this.form.document_name.code.includes('Penandatangan')
				: false;
			if(this.isUnitPengolah){
				return isPenandatangan;
			}else{
				return false;
			}
		},
		isUnitPengolah() {
			return this.form.document_name.code !== undefined
				? this.form.document_name.code.includes('Unit Pengolah')
				: false;
		},
		computedIsUnitPengolah() {
			let isPengolah =  this.form.document_name.code !== undefined
				? this.form.document_name.code.includes('Unit Pengolah')
				: false;
			if(!this.isPengolah && this.isPenandatangan){
				return true;
			}else{
				return isPengolah;
			}
		},
		state() {
			return this.$store.state.drafting_file_declined;
		},
		editMode() {
			return !!this.$route.params.id;
		},
		filteredDocumentName() {
			let filter = this.form.document_type;
			let data = this.documentNameList.filter((elem) => {
				return elem.parent == filter.value;
			});
			return data;
		}
	},
	mounted() {
		this.init();
		if (this.files.length) {
			this.sortFiles();
		}
	},
	watch: {
		'$route': function(){
			window.location.reload();
		},
		files: {
			deep: true,
			handler(newValue) {
				if (newValue.length) {
					if (document.getElementById('sortable-list')) {
						this.sortFiles();
					}
				}
			}
		},
		carbonsList:{
			handler(newValue){
				let filt = this.form.receivers?this.form.receivers:[];
				if(!newValue.length){
					return [];
				}
				let list = newValue.map(el=>{
					el.isDisabled = false
					filt.map(er=>{
						if(el.id === er.id){
							el.isDisabled = true
						}
					})
					if(el.children!==undefined && el.children!==null && el.children.length){
						el.children = this.filterChild(filt,el.children);
					}
					let matchId = new RegExp(this.$store.state.profile.user.unit_id)
					if(!this.form.as_personal && el.isDisabled==false && el.id.match(matchId)){
						el.isDisabled = true
					}
					return el;
				})
				this.filteredCarbonsList = list;
			}
		},
		receiversList:{
			handler(newValue){
				let filt = this.form.carbons?this.form.carbons:[];
				if(!newValue.length){
					return [];
				}
				let list = newValue.map(el=>{
					el.isDisabled = false
					filt.map(er=>{
						if(el.id === er.id){
							el.isDisabled = true
						}
					})
					if(el.children!==undefined && el.children!==null && el.children.length){
						el.children = this.filterChild(filt,el.children);
					}
					let matchId = new RegExp(this.$store.state.profile.user.unit_id)
					if(!this.form.as_personal && el.isDisabled==false && el.id.match(matchId)){
						el.isDisabled = true
					}
					return el;
				})
				this.filteredReceiversList = list;
			}
		},
		'form.receivers': {
			deep: true,
			handler(newValue) {
				let vm = this;
				if (newValue !== null) {
					newValue.map((el) => {
						if (el.id.toString().includes('parent')) {
							let exist = vm.receiversLabeling.find((elm) => elm.id == el.id);
							if (!exist) {
								let c = vm.getLabelSelected(vm.receiversList, el.id);
								vm.receiversLabeling.push({
									id: el.id,
									as_label: true,
									label: c.label
								});
							}
						}
					});
					vm.receiversLabeling = vm.receiversLabeling.filter((el) => {
						return newValue.find((elm) => elm.id == el.id) !== undefined;
					});
				}
				let filt = newValue
				if(!this.carbonsList.length){
					return [];
				}
				let list = this.carbonsList.map(el=>{
					el.isDisabled = false
					filt.map(er=>{
						if(el.id === er.id){
							el.isDisabled = true
						}
					})
					if(el.children!==undefined && el.children!==null && el.children.length){
						el.children = this.filterChild(filt,el.children);
					}
					let matchId = new RegExp(this.$store.state.profile.user.unit_id)
					if(!this.form.as_personal && el.isDisabled==false && el.id.match(matchId)){
						el.isDisabled = true
					}
					return el;
				})
				this.filteredCarbonsList = list;
			}
		},
		'form.carbons': {
			deep: true,
			handler(newValue) {
				let vm = this;
				if (newValue !== null) {
					newValue.map((el) => {
						if (el.id.toString().includes('parent')) {
							let exist = vm.carbonsLabeling.find((elm) => elm.id == el.id);
							if (!exist) {
								let c = vm.getLabelSelected(vm.carbonsList, el.id);
								vm.carbonsLabeling.push({
									id: el.id,
									as_label: true,
									label: c.label
								});
							}
						}
					});
					vm.carbonsLabeling = vm.carbonsLabeling.filter((el) => {
						return newValue.find((elm) => elm.id == el.id) !== undefined;
					});
				}
				let filt = newValue
				if(!this.receiversList.length){
					return [];
				}
				let list = this.receiversList.map(el=>{
					el.isDisabled = false
					filt.map(er=>{
						if(el.id === er.id){
							el.isDisabled = true
						}
					})
					if(el.children!==undefined && el.children!==null && el.children.length){
						el.children = this.filterChild(filt,el.children);
					}
					let matchId = new RegExp(this.$store.state.profile.user.unit_id)
					if(!this.form.as_personal && el.isDisabled==false && el.id.match(matchId)){
						el.isDisabled = true
					}
					return el;
				})
				this.filteredReceiversList = list;
			}
		}
	},
	methods: {
		assignFileDocx(docx){
			this.content = docx;
		},
		grabAllEselonI(list,data,exist){
			// eslint-disable-next-line no-console
			// console.log(list)
			list.map(element=>{
				if(element.eselon !=null && element.eselon.includes('Eselon I') && !element.eselon.includes('Eselon II') && !element.eselon.includes('Eselon III') && !element.eselon.includes('Eselon IV') && !element.isDisabled){
					let ex = false
					exist.map(el=>{
						if(el.id === element.id){
							ex = true
						}
					})
					if(!ex){
						data.push({id:element.id,label:element.label});
					}
				}
				if(element.children!==undefined && element.children!==null && element.children.length){
					this.grabAllEselonI(element.children,data,exist)
				}
			})
		},
		grabAllEselonII(list,data,exist){
			list.map(element=>{
				if(element.eselon !=null && element.eselon.includes('Eselon II') && !element.eselon.includes('Eselon III') && !element.eselon.includes('Eselon IV') && !element.isDisabled){
					let ex = false
					exist.map(el=>{
						if(el.id === element.id){
							ex = true
						}
					})
					if(!ex){
						data.push({id:element.id,label:element.label});
					}
				}
				if(element.children!==undefined && element.children!==null && element.children.length){
					this.grabAllEselonII(element.children,data,exist)
				}
			})
		},
		filterChild(filt,ls){
			if(!ls.length){
				return []
			}
			let list = ls.map(el=>{
				el.isDisabled = false
				filt.map(er=>{
					if(el.id === er.id){
						el.isDisabled = true
					}
				})
				if(el.children!==undefined && el.children!==null && el.children.length){
					el.children = this.filterChild(filt,el.children);
				}
				let matchId = new RegExp(this.$store.state.profile.user.unit_id)
				if(!this.form.as_personal && el.isDisabled==false && el.id.match(matchId)){
					el.isDisabled = true
				}
				return el;
			})
			return list;
		},
		signingProcess(payload) {
			this.sign = !this.sign;
			if (payload.status) {
				const state = {
					loaded: false
				};
				let routeData = this.$router.resolve({
					name: 'drafting-approved detail',
					params: {
						id: this.$route.params.id,
					}
				});
				this.commitState(state)
				let valid = true;
				this.form.status = 1;
				let form = this.form;
				let _this = this;
				Object.keys(form).map((key) => {
					if (key == 'signing' && !_this.isPenandatangan) {
						// eslint-disable-next-line no-empty
					} else if (key == 'processing' && !_this.isUnitPengolah) {
					} else if (
						key == 'as_personal' ||
						key == 'carbons' ||
						key == 'carbonsLabeling' ||
						key == 'files' ||
						key == 'with_header' ||
						key == 'content' ||
						key == 'receiversLabeling' ||
						key == 'attachment' ||
						key == 'with_superior_header'
						// eslint-disable-next-line no-empty
					) {
					} else {
						if (form[key] == '' || form[key] == null || form[key].length == 0) {
							// eslint-disable-next-line no-console
							console.log(key)
							valid = false;
						}
					}
				});
				if (_this.content == '' || _this.content == null) {
					valid = false;
				}
				if (valid) {
					if (_this.editMode) {
						let data = Object.assign(form, {
							receiversLabeling: this.receiversLabeling,
							carbonsLabeling: this.carbonsLabeling,
							files: this.files,
							content: this.content
						});
						const state = {
							loaded: false
						};
						_this.commitState(state)
						let loc = window.location.href
						let basePath = null;
						if(loc.match(/dev-eopis/i)){
							basePath = 'https://dev-eopis.kemlu.go.id'
						}else{
							basePath = 'https://e-office.kemlu.go.id'
						}
						let formData = new FormData();
						this.buildFormData(formData,data);
						axios.post(`drafting-file/${this.$route.params.id}`, formData).then(() => {
							let data = {
								drafting_id: this.$route.params.id,
								url: basePath + routeData.href,
								flag: payload.bsre ? 'bsre' : 'p12',
								nik: JSON.parse(localStorage.getItem('user')).nik,
								passphrase: payload.password,
								paper_size: payload.a4?'A4':'F4'
							};
							axios.post('pre_document_process_file', data).then(() => {
								axios.post(`drafting-file/send/${this.$route.params.id}`, { signature: payload.signature }).then(() => {
									let data = {
										drafting_id: this.$route.params.id,
										url: basePath + routeData.href,
										flag: payload.bsre ? 'bsre' : 'p12',
										nik: JSON.parse(localStorage.getItem('user')).nik,
										passphrase: payload.password,
										paper_size: payload.a4?'A4':'F4'
									};
									axios.post('document_process_file', data).then((res) => {
										let pld = {
											name: res.data.path
										};
										axios.post(`drafting-file/overide-file/${this.$route.params.id}`, pld).then(() => {
											const state = {
												loaded: true
											};
											this.commitState(state)
											this.$router.go(-1);
										});
									}).catch(()=>{
										const state = {
											loaded: true
										};
										this.commitState(state)
										this.notifError({
											title: 'Error',
											text: 'Surat gagal di tandatangani, cek password anda apakah sudah benar'
										});
									});
								});
							}).catch(()=>{
								const state = {
									loaded: true
								};
								this.commitState(state)
								this.notifError({
									title: 'Error',
									text: 'Surat gagal di tandatangani, cek password anda apakah sudah benar'
								});
							});
						});
					}
				} else {
					const state = {
						loaded: true
					};
					this.commitState(state)
					_this.notifError({
						title: 'Error',
						text: 'Semua field yang bertanda bintang dan isi surat harus di isi'
					});
				}
			}
		},
		saveAndPreview(){
			let valid = true;
			this.form.status = 1;
			let form = this.form;
			let _this = this;
			Object.keys(form).map((key) => {
				if (key == 'signing' && !_this.isPenandatangan) {
					// eslint-disable-next-line no-empty
				} else if (key == 'processing' && !_this.isUnitPengolah) {
				} else if (
					key == 'as_personal' ||
					key == 'carbons' ||
					key == 'carbonsLabeling' ||
					key == 'files' ||
					key == 'with_header' ||
					key == 'content' ||
					key == 'receiversLabeling' ||
					key == 'attachment' ||
					key == 'with_superior_header'
					// eslint-disable-next-line no-empty
				) {
				} else {
					if (form[key] == '' || form[key] == null || form[key].length == 0) {
						valid = false;
						// eslint-disable-next-line no-console
						console.log(key);
					}
				}
			});
			if (_this.content == '' || _this.content == null) {
				valid = false;
			}
			if (valid) {
				if (_this.editMode) {
					let data = Object.assign(form, {
						receiversLabeling: this.receiversLabeling,
						carbonsLabeling: this.carbonsLabeling,
						files: this.files,
						content: this.content
					});
					const state = {
						loaded: false
					};
					_this.commitState(state)
					let formData = new FormData();
					this.buildFormData(formData,data);
					// eslint-disable-next-line no-unused-vars
					axios.post(`drafting-file/save-and-preview/${this.$route.params.id}`, formData).then((res) => {
						const state = {
							loaded: true
						};
						_this.commitState(state)
						_this.base64preview = res.data;
						let type = _this.base64preview.split(';')[0];
						_this.type = type.replace('data:', '');
						_this.notifSuccess({
							title: 'Success',
							text: 'Data berhasil di simpan'
						})
					});
				}
			} else {
				_this.notifError({
					title: 'Error',
					text: 'Semua field yang bertanda bintang dan isi surat harus di isi'
				});
			}
		},
		previewDraft() {
			let basePath = null;
			let loc = window.location.href
			if(loc.match(/dev-eopis/i)){
				basePath = 'https://dev-eopis.kemlu.go.id'
			}else{
				basePath = 'https://e-office.kemlu.go.id'
			}
			let routeData = this.$router.resolve({
				name: 'drafting-approved detail',
				params: {
					id: this.$route.params.id,
				}
			});
			let data = {
				id: this.$route.params.id,
				url:basePath + routeData.href
			};
			const state = {
				loaded: false
			};
			this.commitState(state)
			axios.post('drafting-file/preview-draft', data).then((res) => {
				this.base64preview = res.data;
				let type = this.base64preview.split(';')[0];
				this.type = type.replace('data:', '');
				const state = {
					loaded: true
				};
				this.commitState(state)
			});
		},
		send() {
			axios.post(`drafting-file-file/send/${this.$route.params.id}`).then(() => {
				this.$router.go(-1);
			});
		},
		showSign() {
			this.sign = !this.sign;
			// eslint-disable-next-line no-undef
			$('#modal-signature').css('display', 'block');
		},
		submit(status = 2) {
			let valid = true;
			this.form.status = status;
			let form = this.form;
			let _this = this;
			Object.keys(form).map((key) => {
				if (key == 'signing' && !_this.isPenandatangan) {
					// eslint-disable-next-line no-empty
				} else if (key == 'processing' && !_this.isUnitPengolah) {
				} else if (
					key == 'as_personal' ||
					key == 'carbons' ||
					key == 'carbonsLabeling' ||
					key == 'files' ||
					key == 'with_header' ||
					key == 'content' ||
					key == 'receiversLabeling' ||
					key == 'attachment' ||
					key == 'with_superior_header'
					// eslint-disable-next-line no-empty
				) {
				} else {
					if (form[key] == '' || form[key] == null || form[key].length == 0) {
						valid = false;
					}
				}
			});
			if (_this.content == '' || _this.content == null) {
				valid = false;
			}
			// if(_this.files.length == 0){
			//   valid = false
			// }
			if (valid) {
				if (_this.editMode) {
					let data = Object.assign(form, {
						receiversLabeling: this.receiversLabeling,
						carbonsLabeling: this.carbonsLabeling,
						files: this.files,
						content: this.content
					});
					const state = {
						loaded: false
					};
					_this.commitState(state)
					let formData = new FormData();
					this.buildFormData(formData,data);
					axios.post(`drafting-file/${this.$route.params.id}`, formData).then(() => {
						const state = {
							loaded: true
						};
						_this.commitState(state)
						this.$router.go(-1);
					});
				} else {
					let data = Object.assign(form, {
						receiversLabeling: this.receiversLabeling,
						carbonsLabeling: this.carbonsLabeling,
						files: this.files,
						content: this.content
					});
					let formData = new FormData();
					this.buildFormData(formData,data);
					const state = {
						loaded: false
					};
					_this.commitState(state)
					axios.post(`drafting-file`, formData).then(() => {
						const state = {
							loaded: true
						};
						_this.commitState(state)
						this.$router.go(-1);
					}).catch(err=>{
						const state = {
							loaded: true
						};
						_this.commitState(state)
						_this.notifError({
							title: 'Error',
							text: err.response.data
						});
					});
				}
			} else {
				_this.notifError({
					title: 'Error',
					text: 'Semua field yang bertanda bintang dan isi surat harus di isi'
				});
			}
		},
		buildFormData(formData,data,root){
			root = root || '';
			if(root===''){
				for(var key in data){
					this.buildFormData(formData,data[key],key);
				}
			}else{
				if(data instanceof File){
					formData.append(root,data);
					formData.append('test',data);
				}else if((typeof data === 'object' && data) || (Array.isArray(data))){
					formData.append(root,JSON.stringify(data));
				}else if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
				}
			}
		},
		saveOnly(status = 2) {
			let valid = true;
			this.form.status = status;
			let form = this.form;
			let _this = this;
			Object.keys(form).map((key) => {
				if (key == 'signing' && !_this.isPenandatangan) {
					// eslint-disable-next-line no-empty
				} else if (key == 'processing' && !_this.isUnitPengolah) {
				} else if (
					key == 'as_personal' ||
					key == 'carbons' ||
					key == 'carbonsLabeling' ||
					key == 'files' ||
					key == 'with_header' ||
					key == 'content' ||
					key == 'receiversLabeling' ||
					key == 'attachment' ||
					key == 'with_superior_header'
					// eslint-disable-next-line no-empty
				) {
				} else {
					if (form[key] == '' || form[key] == null || form[key].length == 0) {
						valid = false;
						console.log(key)
					}
				}
			});
			if (_this.content == '' || _this.content == null) {
				valid = false;
			}
			// if(_this.files.length == 0){
			//   valid = false
			// }
			if (valid) {
				if (_this.editMode) {
					let data = Object.assign(form, {
						receiversLabeling: this.receiversLabeling,
						carbonsLabeling: this.carbonsLabeling,
						files: this.files,
						content: this.content
					});
					let formData = new FormData();
					this.buildFormData(formData,data);
					const state = {
						loaded: false
					};
					_this.commitState(state)
					axios.post(`drafting-file/${this.$route.params.id}`, formData).then(() => {
						const state = {
							loaded: true
						};
						_this.commitState(state)
						this.$router.go(-1);
					});
				} else {
					let data = Object.assign(form, {
						receiversLabeling: this.receiversLabeling,
						carbonsLabeling: this.carbonsLabeling,
						files: this.files,
						content: this.content
					});
					let formData = new FormData();
					this.buildFormData(formData,data);
					const state = {
						loaded: false
					};
					_this.commitState(state)
					axios.post(`drafting-file/save-only`, formData).then((res) => {
						this.$store.dispatch("drafting_file_declined/onEdit", res.data.data);
					}).catch(err=>{
						const state = {
							loaded: true
						};
						_this.commitState(state)
						_this.notifError({
							title: 'Error',
							text: err.response.data
						});
					});
				}
			} else {
				_this.notifError({
					title: 'Error',
					text: 'Semua field yang bertanda bintang dan isi surat harus di isi'
				});
			}
		},
		cancel() {
			this.$router.go(-1);
		},
		setValueLabeling(el, ty, com, ev) {
			el.as_label = ev;
		},
		sortFiles() {
			var self = this;
			self.$nextTick(function() {
				// eslint-disable-next-line no-unused-vars
				var sortable = Sortable.create(document.getElementById('sortable-list'), {
					draggable: '.draggable',
					onEnd: function(e) {
						var vm = self,
							array = vm.files,
							target = array[e.oldIndex];
						// eslint-disable-next-line no-unused-vars
						let temp = array[e.newIndex];
						// let temp2 = target.id || 0;
						// target.id = temp.id || 0;
						// array[e.newIndex].id = temp2;
						array.splice(e.oldIndex, 1);
						array.splice(e.newIndex, 0, target);
						array = array.filter((el) => el !== undefined);
						self.files = array;
					}
				});
			});
		},
		removeAttachment(uuid) {
			let vm = this;
			let files = vm.uploader.methods.getUploads();
			files.map((element) => {
				if (element.uuid == uuid) {
					let folder;
					vm.files.map((ele) => {
						if (ele.uuid == uuid) {
							folder = ele.folder;
						}
					});
					vm.uploader.methods.setDeleteFileParams({ folder: folder }, element.id);
					vm.uploader.methods.deleteFile(element.id);
				}
			});
			vm.files = vm.files.filter((element) => {
				return element.uuid != uuid;
			});
		},
		download(filepath, filename) {
			axios
				.post('drafting-file/assets/single', { filepath: filepath })
				.then((res) => {
					const linkSource = res.data;
					const downloadLink = document.createElement('a');
					const fileName = filename;

					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
				})
				.catch((err) => {
					// eslint-disable-next-line no-console
					console.log(err);
				});
		},
		prev(string) {
			let routeData = this.$router.resolve({
				name: 'preview.index',
				params: {
					document_type: 'drafting',
					id: this.$route.params.id,
					file: string
				}
			});
			window.open(routeData.href, '_blank');
		},
		getLabelSelected(arr, id) {
			let exist = arr.find((el) => el.id == id);
			if (!exist) {
				arr.map((el) => {
					if (el.children !== null) {
						let cur = this.getLabelSelected(el.children, id);
						if (cur) {
							exist = cur;
						}
					}
				});
			}
			return exist;
		},
		getReceiverChildren(query) {
			if (query == null) {
				axios.get('drafting-file/receivers').then((res) => {
					this.receiversList = res.data.map((el) => {
						return {
							id: el.did,
							label: el.label,
							children: null
						};
					});
				});
			} else {
				axios.get(`drafting-file/receivers?parent=${query.parentNode.id}`).then((res) => {
					this.receiversList = this.receiversList.map(el=>{
						if(el.id == query.parentNode.id){
							el.children = res.data.map((el) => {
								return {
									id: el.did,
									label: el.label
								};
							});
						}
						return el;
					})
					query.callback();
				});
			}
		},
		getSigningChildren(query) {
			if (query == null) {
				axios.get('drafting-file/receivers-superior').then((res) => {
					this.signingsList = res.data.map((el) => {
						return {
							id: el.did,
							label: el.label,
							// children: el.children
							// children: el.children.map((elm) => {
							// 	return {
							// 		id: elm.did,
							// 		label: elm.label
							// 	};
							// })
						};
					});
				});
			} else {
				axios.get(`drafting-file/receivers-superior?parent=${query.parentNode.id}`).then((res) => {
					query.parentNode.children = res.data;
					query.callback();
				});
			}
		},
		getProcessingChildren(query) {
			if (query == null) {
				axios.get('drafting-file/receivers-superior').then((res) => {
					this.processingsList = res.data.map((el) => {
						return {
							id: el.did,
							label: el.label,
							// children: el.children
							// children: el.children.map((elm) => {
							// 	return {
							// 		id: elm.did,
							// 		label: elm.label
							// 	};
							// })
						};
					});
				});
			} else {
				axios.get(`drafting-file/receivers-superior?parent=${query.parentNode.id}`).then((res) => {
					query.parentNode.children = res.data;
					query.callback();
				});
			}
		},
		getCarbonChildren(query) {
			if (query == null) {
				axios.get('drafting-file/receivers').then((res) => {
					this.carbonsList = res.data.map((el) => {
						return {
							id: el.did,
							label: el.label,
							children: null
						};
					});
				});
			} else {
				axios.get(`drafting-file/receivers?parent=${query.parentNode.id}`).then((res) => {
					this.carbonsList = this.carbonsList.map(el=>{
						if(el.id == query.parentNode.id){
							el.children = res.data.map((el) => {
								return {
									id: el.did,
									label: el.label
								};
							});
						}
						return el;
					})
					query.callback();
				});
			}
		},
		documentClasification(query) {
			axios.get(`clasificationdoc/list?s=${query}`).then((res) => {
				this.documentClasificationList = res.data.items;
			});
		},
		documentName(query) {
			axios.get(`clasification/list-active?s=${query}`).then((res) => {
				this.documentNameList = res.data.items;
			});
		},
		documentType(query) {
			axios.get(`document_categories/list?s=${query}`).then((res) => {
				this.documentTypeList = res.data.items;
			});
		},
		documentSpeed(query) {
			axios.get(`document_speeds/list?s=${query}`).then((res) => {
				this.documentSpeedList = res.data.items;
			});
		},
		attachmentType(query) {
			axios.get(`attachment_types/list?s=${query}`).then((res) => {
				this.attachmentTypeList = res.data.items;
				for (let at in res.data.items) {
					if (res.data.items[at].text == 'Lembar' && this.form.attachment_type.text == 'Lembar') {
						this.form.attachment_type = res.data.items[at];
					}
				}
			});
		},
		setValue(model, data) {
			this.form[model] = data;
		},
		urgencies(query) {
			axios.get(`document_urgencies/list?s=${query}`).then((res) => {
				this.urgenciesList = res.data.items;
			});
		},
		commitState(state) {
			this.$store.commit('drafting_file_declined/STATE', state);
		},
		fillEditData(data) {
			let form = this.form;
			this.canEdit = data.canEdit;
			this.to_staff = data.to_staff ? true : false;
			this.finalApproval = data.finalApproval;
			this.finalSender = data.finalSender;
			form.agenda_number = data.agenda_number;
			form.document_date = data.document_date;
			form.document_urgency = {
				value: data.urgency.id,
				text: data.urgency.name
			};
			form.document_speed = {
				value: data.document_speed.id,
				text: data.document_speed.name
			};
			form.document_type = {
				value: data.category.id,
				text: data.category.name
			};
			form.attachment_type = {
				value: data.attachment.id,
				text: data.attachment.name
			};
			form.document_name = {
				value: data.status.id,
				text: data.status.name,
				code: data.status.code,
				file: data.status.file,
				link_file: data.status.link_file,
			};
			form.document_clasification = {
				value: data.clasification.id,
				text: data.clasification.name_classification
			};

			form.with_header = data.with_header ? true : false;
			form.subject = data.subject;
			form.attachment = data.attachment_total;
			this.content = data.file;
			this.link_file = data.link_file;
			this.files = data.attachments;
			if (this.files.length) {
				this.sortFiles();
			}
			let r = data.receivers.filter((er) => er.pivot.labeling == 1 && er.pivot.flag == 'sb');
			let re = data.receivers_label.filter((er) => er.pivot.is_labeling == 1 && er.pivot.flag == 'sb');
			form.receivers = [];
			r.map((el) => {
				// this.getReceiverChildren(`parent-${el.pivot.unit_group_id}`);
				form.receivers.push({id:`children-${el.pivot.unit_group_id}-${el.id}`,label:el.name});
			});
			re.map((el) => {
				form.receivers.push({id:`parent-${el.id}`,label:el.name});
			});
			r = data.receivers.filter((er) => er.pivot.labeling == 1 && er.pivot.flag == 'cc');
			re = data.receivers_label.filter((er) => er.pivot.is_labeling == 1 && er.pivot.flag == 'cc');
			form.carbons = [];
			r.map((el) => {
				// this.getCarbonChildren(`parent-${el.pivot.unit_group_id}`);
				form.carbons.push({id:`children-${el.pivot.unit_group_id}-${el.id}`,label:el.name});
			});
			re.map((el) => {
				form.carbons.push({id:`parent-${el.id}`,label:el.name});
			});
			if(data.processing_unit!==null && data.processing_unit!==undefined){
				form.processing = {id:`children-${data.processing_unit.id}`,label:data.processing_unit.name}
			}else{
				form.processing = {id:`children-${data.unit_sender.id}`,label:data.unit_sender.name}
			}
			if(data.processing_unit!==null && data.processing_unit!==undefined){
				form.signing = {id:`children-${data.unit_sender.id}`,label:data.unit_sender.name}
			}
			// this.processingsList.map((el) => {
			// 	let exist = null;
			// 	let text = `children-${data.unit_sender.id}`;
			// 	if (text == el.id) {
			// 		exist = el;
			// 	}
			// 	if (exist) {
			// 		form.processing = exist.id;
			// 	}
			// });
			// this.signingsList.map((el) => {
			// 	let exist = null;
			// 	let text = `children-${data.approve_unit}`;
			// 	if (text == el.id) {
			// 		exist = el;
			// 	}
			// 	if (exist) {
			// 		form.signing = exist.id;
			// 	}
			// });

			form.with_superior_header = data.with_superior_header ? true : false;
			form.as_personal = data.as_personal ? true : false;
		},
		init() {
			// eslint-disable-next-line no-unused-vars
			let _this = this;
			this.attachmentType('');
			if (this.editMode) {
				this.previewDraft();
				this.disableAll = true;
				axios.get('drafting-file/receivers').then((res) => {
					this.receiversList = res.data.map((el) => {
						return {
							id: el.did,
							label: el.label,
							children: null
						};
					});
					// this.getProcessingChildren(null);
					// this.getSigningChildren(null);
					this.carbonsList = this.receiversList;
					axios.get(`drafting-file/${this.$route.params.id}`).then((ress) => {
						this.processingsList = ress.data.data.list_superior;
						this.signingsList = ress.data.data.list_superior;
						const state = {
							isEdit: false,
							isAdd: true,
							isDetail: false,
							loaded: true
						};
						this.fillEditData(ress.data.data);
						this.commitState(state);
					});
				});
			} else {
				this.getReceiverChildren(null);
				this.getCarbonChildren(null);
				this.getProcessingChildren(null);
				this.getSigningChildren(null);
				const state = {
					isEdit: false,
					isAdd: true,
					isDetail: false,
					loaded: true
				};
				this.commitState(state);
			}
			axios.get(`/document_out/new`).then((response) => {
				if (!this.editMode) {
					this.form.agenda_number = response.data.data.agenda_number;
					this.form.document_date = response.data.data.document_date;
				}
			});
		}
	}
};
</script>
